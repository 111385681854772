/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/
html {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Fira Sans", sans-serif;
  background: #f7f7f7;
  font-weight: 300;
  overflow: hidden; }

*,
*:before,
*:after {
  box-sizing: inherit; }

#root {
  min-height: 100vh;
  height: 100%; }

.left-sidebar {
  min-height: 100vh;
  background: #3e588f;
  list-style-type: none; }
  @media screen and (max-width: 600px) {
    .left-sidebar {
      display: none; } }
  .left-sidebar a {
    color: #f7f7f7; }
    .left-sidebar a:hover {
      color: #dedede;
      text-decoration: none; }
